<template>
  <div class="login-container">
    <div class="formWrap">
      <el-form
        ref="loginForm"
        :model="loginForm"
        :rules="loginRules"
        class="login-form"
        label-position="left"
        @submit.native.prevent
      >
        <!-- <h1 class="logo"/> -->
        <div class="title-container">
          <h3 class="title">{{ login.title }}</h3>
        </div>
        <!-- prop需要验证的对象 -->
        <el-form-item prop="username">
          <div class="itemContainer">
            <span class="userIconWrap">
              <span class="userIcon" />
            </span>
            <el-input
              ref="username"
              v-model="loginForm.username"
              :placeholder="login.username"
              name="username"
              type="text"
              tabindex="1"
              maxlength="20"
              @keyup.enter.native="handleLogin"
            />
          </div>
        </el-form-item>

        <el-tooltip
          v-model="capsTooltip"
          content="Caps lock is On"
          placement="right"
          manual
        >
          <el-form-item prop="password">
            <div class="itemContainer">
              <span class="userIconWrap">
                <span class="passwordIcon" />
              </span>
              <!-- @blur="capsTooltip = false" -->
              <el-input
                ref="password"
                :key="passwordType"
                v-model="loginForm.password"
                :type="passwordType"
                :placeholder="login.password"
                name="password"
                maxlength="16"
                tabindex="2"
                @keyup.native="checkCapslock"
                @keyup.enter.native="handleLogin"
              />
            </div>
          </el-form-item>
        </el-tooltip>

        <el-form-item prop="verity">
          <div class="itemContainer verityContainer">
            <span class="userIconWrap">
              <span class="verutyIcon" />
            </span>
            <el-input
              ref="verity"
              v-model="loginForm.verity"
              :placeholder="login.verity"
              name="verity"
              type="text"
              maxlength="4"
              tabindex="3"
              @keyup.enter.native="handleLogin"
            />
            <img
              :src="vetiryInfo.image"
              class="verity"
              alt="点击刷新"
              @click="getVerifyImage()"
            />
          </div>
        </el-form-item>
        <div class="passwordContain">
          <!-- <span class="forget" @click="forget">忘记密码</span> -->
          <el-checkbox v-model="rememberIcon" @change="rememberUser"
            >记住用户名</el-checkbox
          >
        </div>
        <el-button
          ref="button"
          :loading="loading"
          :disabled="loading"
          round
          style="width: 100%"
          @click.native.prevent="handleLogin"
          >{{ login.logIn }}</el-button
        >
      </el-form>
      <div v-show="hideDes" class="des">
        <p>上海树凯信息科技有限公司</p>
      </div>
    </div>
    <!-- <PassOperation
      :visible.sync="addVisible"
      :dialogtitle.sync="dialogTitle"
      :dialogtype.sync="dialogType"
      :isloginclick="true"
      @changePass="changePass"
    /> -->
  </div>
</template>
<script>
import { validUsername } from "@/utils/validate";
// import { Encrypt } from '@/utils/index'
import { getVerifyImage } from "@/apis/user.js";
import { setCookie, removeCookie, getCookie } from "@/utils/auth";
// import PassOperation from '@/components/passwordOperation'
export default {
  name: "Login",
  // components: {
  //   PassOperation
  // },
  data() {
    // const validateUsername = (rule, value, callback) => {
    //   // //console.log(validEmail(value))
    //   if (!isPhone(value)) {
    //     callback(new Error('请输入正确的手机号'))
    //   } else {
    //     callback()
    //   }
    // }
    const validatePassword = (rule, value, callback) => {
      if (value === "" || value.length < 6) {
        callback(new Error("密码不能小于6位数"));
      } else if (validUsername(value)) {
        callback(new Error("密码不能包含空格"));
      } else {
        callback();
      }
    };
    const validateVerity = (rule, value, callback) => {
      if (!validUsername(value) && value.length === 4) {
        callback();
      } else {
        callback(new Error("请输入正确的验证码"));
      }
    };
    return {
      loginForm: {
        username: "",
        password: "",
        verity: "",
      },
      loginRules: {
        // 表单验证规则
        username: [
          {
            required: true,
            trigger: "handleLogin",
            message: "请输入账号",
          },
        ],
        password: [
          {
            required: true,
            trigger: "handleLogin",
            validator: validatePassword,
          },
        ],
        verity: [
          { required: true, trigger: "handleLogin", validator: validateVerity },
        ],
      },
      passwordType: "password",
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      login: {
        title: "欢迎使用招商名片后台管理系统",
        logIn: "登录",
        username: "请输入用户名",
        password: "请输入密码",
        verity: "请输入验证码",
      },
      rememberIcon: false,
      docmHeight: "0", // 默认屏幕高度
      showHeight: "0", // 实时屏幕高度
      hideDes: true, // 显示或者隐藏footer,
      isResize: false, // 默认屏幕高度是否已获取
      addVisible: false, // 对话框显示隐藏
      dialogType: 0, // 弹框类型
      dialogTitle: "", // 弹框标题
      vetiryInfo: {},
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
    showHeight: function () {
      if (this.docmHeight > this.showHeight) {
        this.hideDes = false;
      } else {
        this.hideDes = true;
      }
    },
  },

  mounted() {
    this.getVerifyImage();
    // if (isMobile()) {
    //   this.docmHeight = document.documentElement.clientHeight
    //   window.onresize = () => {
    //     return (() => {
    //       this.showHeight = document.body.clientHeight
    //     })()
    //   }
    // }
    if (getCookie("govUsername")) {
      this.loginForm.username = getCookie("govUsername");
      this.rememberIcon = true;
    }
  },
  methods: {
    // 回车事件

    async getVerifyImage() {
      const { result } = await getVerifyImage();
      this.vetiryInfo = result;
    },
    checkCapslock({ shiftKey, key } = {}) {
      if (key && key.length === 1) {
        if (
          (shiftKey && key >= "a" && key <= "z") ||
          (!shiftKey && key >= "A" && key <= "Z")
        ) {
          this.capsTooltip = true;
        } else {
          this.capsTooltip = false;
        }
      }
      if (key === "CapsLock" && this.capsTooltip === true) {
        this.capsTooltip = false;
      }
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        // 表单验证
        if (valid) {
          if (this.loading) return;
          this.loading = true;

          this.loginForm.key = this.vetiryInfo.key;
          // //console.log(this.loginForm)
          this.$store
            .dispatch("login", this.loginForm)
            .then(() => {
              if (this.rememberIcon) {
                setCookie("govUsername", this.loginForm.username);
              } else {
                removeCookie("govUsername");
              }
              this.$message1("登录成功", "success");
              this.$store.commit("SET_PROGRAMINFO", "");
              this.$router.push({ path: "/" }).catch(() => {});

              // setCookie('firstLogin', 0)
              // }
              setTimeout(() => {
                this.loading = false;
              }, 500);
            })
            .catch((err) => {
              // console.log(err)
              if (err.code === 604) {
                // this.loginForm.verity = ''
              } else if (err.code === 605) {
                this.loginForm.password = "";
              }
              this.loginForm.verity = "";
              this.getVerifyImage();
              this.loading = false;
            });
        } else {
          // console.log('error submit!!')
          return false;
        }
      });
    },
    // 记住用户名
    rememberUser(e) {
      this.rememberIcon = e;
    },
    // 忘记密码
    forget() {
      // this.addVisible = true
      // this.dialogType = 2 // 弹框类型
      // this.dialogTitle = '请输入企业邮箱'
      this.$message1("该功能暂未开放", "warning");
    },
    // changePass() {
    //   this.loginForm.password = ''
    //   this.loginForm.verity = ''
    //   this.getVerifyImage()
    //   this.$message1('新密码设置成功', 'success')
    // },
    // blur() {
    //   setTimeout(function() {
    //     var scrollHeight =
    //       document.documentElement.scrollTop || document.body.scrollTop || 0
    //     window.scrollTo(0, Math.max(scrollHeight - 1, 0))
    //   }, 100)
    // }
  },
};
</script>
<style lang="scss">
@import "../../styles/variables";
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;
$cursor: #000;
.login-container {
  .el-input {
    width: 220px;
    input {
      border: 0px;
      -webkit-appearance: none;
      background: transparent;
      padding: 0 5px 0 10px;
      color: #000;
      caret-color: $cursor;
      border-radius: 19px;
      font-size: 14px;
      line-height: 18px;
      height: 38px;
      outline: 0;
    }
    input:-webkit-autofill {
      box-shadow: 0 0 0px 1000px #fff inset;
    }
  }

  .el-form-item {
    margin: 16px 0 0 0;
    border-radius: 19px;
    background: #f2f2f2;
    border: solid 1px #e8e8e8;
  }
  .el-button {
    margin-top: 16px;
    font-size: 16px;
    background: url("../../assets/login_images/button.png");
    color: #fff;
    line-height: 38px;
    border-color: #fff;
    border-radius: 19px !important;
    padding: 0 23px !important;
  }
  .el-button:focus,
  .el-button:hover {
    background: url("../../assets/login_images/button.png");
    border-color: #fff;
    color: #fff;
  }
}
</style>
<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;
.login-container {
  min-height: 100vh;
  width: 100%;
  background: url("../../assets/login_images/bg.jpg") no-repeat;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  .formWrap {
    width: 370px;
    background: #fff;
    // margin:0 auto;
    // height:460px;
    border-radius: 12px;
    padding-bottom: 30px;
  }
  .login-form {
    position: relative;
    width: 270px;
    max-width: 100%;
    // height:100%
    margin: 0 auto;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .logo {
    width: 68px;
    height: 66px;
    margin: 45px auto 0;
    background: url("../../assets/login_images/logo.png");
  }

  .title-container {
    position: relative;

    .title {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: 0px;
      color: #808080;
      margin: 30px 0 8px 9px;
    }
  }
  .itemContainer {
    display: flex;
    height: 38px;
    align-items: center;
  }
  .userIconWrap {
    width: 26px;
    height: 14px;
    margin-left: 18px;
    border-right: 0.5px solid #d2d2d2;
    span {
      width: 12px;
      height: 14px;
      display: inline-block;
      background-size: cover;
      vertical-align: top;
    }
    .userIcon {
      background: url("../../assets/login_images/user.png") no-repeat;
    }
    .passwordIcon {
      background: url("../../assets/login_images/password.png") no-repeat;
    }
    .verutyIcon {
      width: 14px;
      height: 14px;
      background: url("../../assets/login_images/verity.png") no-repeat;
      background-size: cover;
    }
  }
  .verityContainer {
    overflow: hidden;
    .el-input {
      width: 140px;
    }
    .verity {
      height: 38px;
      flex: 1;
      // background: #f2f2f2;
      border-radius: 19px;
      text-align: center;
      font-size: 16px;
      color: #6f7593;
      cursor: pointer;
      // border:1px solid #f2f2f2;
    }
  }
  .passwordContain {
    display: flex;
    height: 16px;
    align-items: center;
    justify-content: space-between;
    color: #808080;
    padding: 0 20px 0 14px;
    margin-top: 16px;
    .forget {
      line-height: 1;
      font-size: 14px;
      cursor: pointer;
    }
  }
  .des {
    text-align: center;
    color: #fff;
    font-size: 16px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 54px;
    p {
      line-height: 1;
    }
  }
  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }

  @media only screen and (max-width: 470px) {
    .thirdparty-button {
      display: none;
    }
  }
}
</style>
